.information-tooltip__icon {
  svg {
    fill: hsl(252, 100%, 50%);
    width: 1.3rem;
    height: 1.3rem;
  }
}

.tippy-box[data-theme~='returnista'] {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  border: solid 1px var(--main-border-color);
  font-weight: 700;
}