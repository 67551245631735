.price {
  height: 1.2rem;
  font-weight:var(--font-weight-h1);
  @include size("font-size", 22px);
  @include size("margin", 20px, 0);
  text-align: center;
}

.form__price {
  color: var(--price-color);
}