// We are intentionally not Using Bulma standards here as there is too big a difference with the design
$label-base-margin: 6;
$label-up-margin: 40;
$span-up-margin: 40;
$border-margin-base: 6;

.control {
  position: relative;
  // @include size(margin-bottom, 50);

  &.control--s {
    @include size(max-width, 145px);
  }

  &.control--xs {
    @include size(max-width, 68px);
  }

  .control__label,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  .control__label {
    font-weight: var(--font-weight-normal);
    @include size("font-size", 18px);
    position: absolute;
    @include size(bottom, $label-base-margin);
    @include size(left, 8);

    @include mobile {
      width: 100%;
    }
  }

  span {
    font-weight: var(--font-weight-normal);
    @include size("font-size", 14px);
    color: var(--error-text-color);
    position: absolute;
    @include size(top, $span-up-margin);
    @include size(left, 0);
  }

  &:after {
    content: "";
    display: block;
    // width: calc(100% - 12px);
    @include size(height, 2);
    @include size(margin-bottom, $border-margin-base);
    background-color: var(--main-text-color);
  }

  &.filled {
    .control__label {
      color: var(--main-text-color);
      @include size(bottom, $label-up-margin);
    }

    &:after {
      background-color: var(--main-text-color);
    }
  }

  &.error {
    input {
      color: var(--error-text-color);
      background-color: var(--error-bg-color);
    }

    .control__label {
      color: var(--main-text-color);
      @include size(bottom, $label-up-margin);
    }

    span {
      font-weight: var(--font-weight-normal);
      @include size("font-size", 14px);
      color: var(--error-text-color);
      position: absolute;
      @include size(top, $span-up-margin);
      @include size(left, 0);
      width: 20rem;
    }
  }

  &.submitting {
    .control__label {
      @include size(bottom, $label-up-margin);
    }
  }

  &.hidden {
    display: none;
  }

  input {
    font-weight: var(--font-weight-normal);
    @include size("font-size", 17px);
    @include size("letter-spacing", 0.5px);
    padding-left: .5rem;
    background: none;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
    position: relative;

    &::placeholder {
      color: transparent;
    }

    &:focus+.control__label,
    &:valid+.control__label,
    &:not(:required)[value=""]:focus+.control__label {
      @include size(bottom, $label-up-margin);
    }

    &:not(:required)[value=""]+.control__label {
      @include size(bottom, $label-base-margin);
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &:active,
    &:focus {
      border: none;
      box-shadow: none;
    }

    // &:disabled {
    //   color: var(--input-text-color);
    // }
  }
}

// Overriding Bulma settings
.field.is-grouped>.control:not(:last-child) {
  @include size(margin-bottom, 0);
}

.field.is-grouped {
  &>.field {
    width: -webkit-fill-available;
    @include size(margin-right, 12px);
    @include mobile {
      @include size(margin-right, 0);
    }
  }
}

.field:not(:last-child) {
  @include size("margin-bottom", 0);
}

.input-field__wrapper {
  position: relative;

  &__mobile-padding {
    @include mobile {
      margin-top: 3.5rem;
    }
  }

  .input-field__check {
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
    @include size("width", 38px);
    @include size("height", 38px);
    position: absolute;
    left: auto !important;
    @include size("right", -20px);
    top: 0.4375rem !important;
    // background: url("../../assets/icons/check.svg") no-repeat;
    // background-size: 26px;
    opacity: 0;
    z-index: 10;

    svg {
      @include size("width", 26px);
      @include size("height", 26px);

      #ovalCopy2 {
        fill: var(--input-check-bg-color);
      }

      #Path {
        stroke: var(--input-check-text-color);
      }
    }
  }

  &.input-field__wrapper-is-valid {
    .input-field__check {
      opacity: 1;
    }
  }
}