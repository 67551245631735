.edit-container {
  @include mobile {
    margin-right: 0 !important;
  }

  .edit__parcels-container {
    @include size(margin-bottom, 26px);

    @include mobile {
      margin-left: 10%;
    }
  }
}