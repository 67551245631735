.input-postalcode {
  flex-grow: 0 !important;
  width: 100px;
}

input[type=number].no-arrows, input[type=number].no-arrows:hover {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    opacity: 1;
  }

  /* Firefox */
  -moz-appearance: textfield;
}