.cta-button {
  font-weight: var(--font-weight-h2);
  @include size("font-size", 22px);
  @include size("margin-bottom", 20px);
  @include size("max-width", 450px);
  align-items: center;
  background: var(--prm-btn-bg-color);
  border-color: var(--prm-btn-border-color);
  border-width: var(--prm-btn-border-width);
  box-shadow: var(--prm-btn-border-box-shadow);
  color: var(--prm-btn-text-color);
  display: flex;
  justify-content: space-between;
  border-radius: var(--prm-btn-border-radius);
  // width: 100%;
  transition: background-color 200ms linear;

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    transition: all ease .25s;
    border-color: var(--prm-btn-border-color--hover);
    background: var(--prm-btn-bg-color--hover);
    color: var(--prm-btn-text-color--hover);
  }

  @include large-mobile {
    @include size("font-size", 24px);
  }

  @include desktop {
    @include size(max-width, 450px);
  }

  .button__text {
    @include size("margin", 0, 6px, 0, 0);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mobile {
      @include size("font-size", 18px);
    }
  }

  &.cta-button--disabled,
  &.cta-button--disabled:hover {
    background-color: var(--prm-btn-bg-color--disabled);
    color: var(--prm-btn-text-color--disabled);
    box-shadow: none;
    cursor: default;

    .arrow__triangle {
      background-color: var(--prm-btn-text-color--disabled);
    }
  }

  &.cta-button--disabled:hover .arrow__triangle {
    background-color: var(--prm-btn-text-color--disabled);
  }

  &:hover .arrow__triangle {
    transition: all ease .25s;
    background-color: var(--prm-btn-text-color--hover);
  }
}