.confirmation__message {
  text-align: center;
}

.confirmation__box {
  font-weight: var(--font-weight-normal);
  @include size("font-size", 20px);
  background-color: var(--confirmation-box-bg-color);
  border-radius: 20px;
  border: 2px solid var(--box-border-color);
  margin: 2rem 0;
  padding: 0.75rem 0;
  box-shadow: 0 0.625rem 1.875rem -0.9375rem var(--box-box-shadow-color);

  p {
    display: flex;
    padding: 0 0.75rem;
    align-items: center;
    @include size("line-height", 27px);
  }

  hr {
    display: block;
    margin: 0.5rem 0;
    height: 2px;
    background-color: var(--box-border-color);
  }

  .confirmation__box--highlight {
    color: var(--box-text-color--active);
    font-weight: var(--font-weight-h1);
    @include size("font-size", 18px);
  }

  .confirmation__box--header {
    font-weight: var(--font-weight-h1);
    @include size("font-size", 20px);
  }

  .has-vcentered {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.confirmation__block {
  margin-bottom: 0 !important;
}

.confirmation_street_name {
  font-weight: var(--font-weight-normal);
  @include size("font-size", 18px);
}

.confirmation__box--email {
  p {
    text-align: center;
  }
}

.confirmation__dropoff {
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    color: var(--a-color);
    padding: 0 0.75rem;
    text-decoration: underline;
  }
}

.confirmation__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .confirmation__icon {
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;

    & circle {
      fill: var(--box-border-color--active);
    }

    .confirmation__icon-pin {
      max-width: 250%;
    }
  }
}

.confirmation__edit--link {
  background: none;
  border: none;
  color: var(--main-text-color);
  @include size("font-size", 20px);
  font-weight: var(--font-weight-normal);
  text-decoration: underline;
  letter-spacing: 0;
  display: flex;

  cursor: pointer;

  .arrow {
    margin: 9px 0 0 8px;

    .arrow__triangle,
    .arrow__triangle::before,
    .arrow__triangle::after {
      width: 0.3rem;
      height: 0.3rem;
    }
  }
}

//dropoff arrow

.confirmation__dropoff__arrow {
  background-color: var(--prm-btn-text-color);
  position: relative;
  text-align: left;
  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
}

.confirmation__dropoff__arrow:before,
.confirmation__dropoff__arrow:after {
  background-color: inherit;
  content: "";
  position: absolute;
}

.confirmation__dropoff__arrow,
.confirmation__dropoff__arrow:before,
.confirmation__dropoff__arrow:after {
  @include size("height", 6px);
  @include size("width", 6px);
  border-top-right-radius: 30%;
}

.confirmation__dropoff__arrow:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}

.confirmation__dropoff__arrow:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}