@import 'assets/stylesheets/colors';

.slick-track {
  display: flex;
  align-items: center;
}

.slick-list {
  margin: 0 -10px;
  padding: 0 !important;
}

.slick-slide {
  @include size("border-radius", 15px);
  @include size("font-size", 38px);
  @include size("height", 88px);
  @include size("width", 88px);
  margin: 0 5px;
  position: relative;
  background: var(--scn-btn-bg-color);
  color: var(--scn-btn-text-color);
  border: 0.1875rem solid var(--scn-btn-border-color);

  .dayslider__item {
    height: 100%;
    width: 100%;
    position: relative;

    .dayslider__day-of-week {
      @include size("font-size", 16px);
      width: 100%;
      display: flex;
      justify-content: center;
      // visibility: hidden;
      margin-top: 4px;
    }

    .dayslider__date {
      font-weight: var(--font-weight-h1);
      @include size("font-size", 42px);
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 80%;
      justify-content: center;
      margin-top: -8px;
    }
  }


  &.slick-current {
    background: var(--scn-btn-bg-color--active);
    border: 0 solid transparent;
    color: var(--scn-btn-text-color--active);

    .dayslider__day-of-week {
      visibility: visible;
    }
  }
}
