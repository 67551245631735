.App-header {
  margin-top:1.5rem;
  padding: 0;
  position: relative;

  .change-language {
    right: 0;
  }

  .sections {
    margin-right:0;
    margin-left:0;
    margin-bottom:0;
    margin-top:0;
    position: relative;
  }

  .switch-language-section {
    position: absolute;
    right: 0;
    z-index: 9;
    height: 100%;
  }
}