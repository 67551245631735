
.arrow {
  @include size("height", 10px);
  @include size("margin", 0, 5px);
  @include size("width", 10px);
  align-items: center;
  display: flex;
  justify-content: center;

  &.arrow--top {
    transform: rotate(0deg);
  }

  &.arrow--right {
    transform: rotate(90deg);
  }

  &.arrow--bottom {
    transform: rotate(180deg);
  }

  &.arrow--left {
    transform: rotate(270deg);
  }

  .arrow__triangle {
    background-color: var(--prm-btn-text-color);
    position: relative;
    text-align: left;
  }


  .arrow__triangle:before,
  .arrow__triangle:after {
    background-color: inherit;
    content: "";
    position: absolute;
  }

  .arrow__triangle,
  .arrow__triangle:before,
  .arrow__triangle:after {
    @include size("height", 8px);
    @include size("width", 8px);
    border-top-right-radius: 30%;
  }

  .arrow__triangle {
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  }

  .arrow__triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  .arrow__triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
}