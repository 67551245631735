.intro-title {
  h1 {
    @include size(font-size, 24px);
  }

  h1 em {
    display: block;
    @include size(font-size, 36px);
    @include size(line-height, 42px);
    font-style: normal;
    // background: -webkit-linear-gradient(45deg, var(--brand-color-1), var(--brand-color-2));
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: var(--brand-text-color);
  }
}