.landing-page {
  align-items: center;
  height: 100%;
  
  // padding: 0 2rem;
  margin-left: -11px !important;

  .lottie-wrapper {
    margin: auto;
    @include mobile {
      width: 280px;
    }
    @include large-mobile {
      width: 320px;
    }
    @include desktop {
      width: 400px;
    }
  }

  @include mobile {
    .cta-button {
      //margin-left: -40px;
    }
  }

  .emoji-column {
    position: relative;

    .emoji__container {
      @include size("height", 42px);
      @include size("width", 42px);
      align-items: center;
      background: var(--prm-btn-text-color);
      border-radius: 50%;
      box-shadow: -0.4rem 0.5rem 1.2rem 0.3rem rgba(black, 0.15);
      display: flex;
      margin-top: 5px;
      position: absolute;
      right: 15px;

      @include large-mobile {
        margin-top: 15px;
        @include size("height", 62px);
        @include size("width", 62px);
      }

      @include desktop {
        right: 50px;
      }
    }

    .emoji__wrapper {
      display: flex;
      justify-content: center;
      width: 100%;

      span {
        display: flex;
        z-index: 1;

        img {
          height: 28px;

          @include large-mobile {
            height: 40px;
          }
        }
      }
    }
  }
}
