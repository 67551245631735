.confirmation-emoji__container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  .confirmation-emoji__wrapper {
    background: var(--main-gradient);
    border-radius: 50%;
    width: 116px;
    height: 116px;
    display: flex;
    align-items: center;
  }
}
