.is-grouped-with-mobile {
  @media(max-width: 820px) {
    flex-direction: column;
    //gap: 2.8rem;
  }
}

.custom-return-reason-field {
  @media(max-width: 820px) {
    // margin-top: 5rem !important;
    // margin-bottom: 5rem !important;
  }
}

.return-reason-field {
  // @media(max-width: 820px) {
  //   margin-bottom: 4rem !important;
  // }
}

.item-field {
  @media(max-width: 820px) {
    // margin-top: 1.5rem !important;
  }
}


.image-field {
  @media(max-width: 820px) {
    // margin-bottom: 4rem !important;
  }
}