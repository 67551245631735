.time-slots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include size("margin", 20px, 0);
  padding: 0 8px 0 8px;

  .time-slots__wrapper {
    @include size(padding, 6px);
    width: 50%;
    &.default {
      flex-grow: 1;
      width: 100%;
    }

    @include desktop {
      width: 33%;
    }
  }
}
