// Globals
$size-baseline: 16;

@import './assets/stylesheets/mixins/size';
@import './assets/stylesheets/mixins/media-queries';
@import './assets/stylesheets/vars';
@import './assets/stylesheets/bulma-configuration';
@import './assets/stylesheets/fonts';

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
@import '../node_modules/bulma/sass/layout/footer.sass';
@import '../node_modules/bulma/sass/grid/columns.sass';

@import 'assets/stylesheets/globals/header';

@import 'components/Arrow';
@import 'components/Layout/SwitchLanguage';
@import 'components/Layout/Logo';
@import 'components/Layout/Footer';
@import 'components/CheckBox';
@import 'components/ConfirmationEmoji';
@import 'components/CtaButton';
@import 'components/FieldInput';
@import 'components/FieldSelect';
@import 'components/IntroTitle';
@import 'components/Price';
@import 'components/ProgressBar';
@import 'components/ShamedEmoji';
@import 'components/Title';

@import 'containers/Address';
@import 'containers/LoadingPage';
@import 'containers/Timeslots/Fields/Days/slick'; // Leave this above dayslider(!)
@import 'containers/Timeslots/Fields/Days/dayslider';
@import 'containers/Timeslots/Fields/Timeslots/Button';
@import 'containers/Timeslots/Fields/Timeslots';
@import 'containers/Timeslots/Loading';
@import 'containers/Timeslots/NotAvailable';
@import 'containers/Confirmation';
@import 'containers/Edit';
@import 'containers/Timeslots';
@import 'containers/ReturnType';
@import 'containers/Details/Fields';
@import 'containers/Error';

:root {
  --font-family-primary: Nunito;
  --font-family-headers: Nunito;
  --font-weight-h1: 800;
  --headers-text-color: transparent;
  --headers-text-color--dimmed: transparent;
  --a-color: transparent;
  --prm-btn-bg-color: transparent;
  --prm-btn-text-color: transparent;
  --prm-btn-bg-color--hover: transparent;
  --prm-btn-text-color--hover: transparent;
  --prm-btn-bg-color--disabled: transparent;
  --prm-btn-text-color-disabled: transparent;
  --scn-text-color: transparent;
  --scn-border-color: transparent;
  --error-text-color: transparent;
  --error-border-color: transparent;
  --error-bg-color: transparent;
  --input-text-color: transparent;
  --main-gradient-color-1: transparent;
  --main-gradient-color-1--hover: transparent;
  --main-gradient-color-2: transparent;
  --main-gradient-color-2--hover: transparent;
  --footer-bg-color-1: transparent;
  --footer-bg-color-2: transparent;
  --footer-text-color: transparent;
  --footer-logo-color: transparent;
  --scn-gradient-color-1: transparent;
  --scn-gradient-color-1--hover: transparent;
  --scn-gradient-color-2: transparent;
  --scn-gradient-color-2--hover: transparent;
  --main-text-color: transparent;
  --main-text-color--light: transparent;
  --main-bg-color: transparent;
  --main-bg-color--light: transparent;
  --main-border-color: transparent;
  --progress-bar-bg-color: transparent;
  --progress-bar-bg-color--active: transparent;
  --progress-bar-step-bg-color: transparent;
  --progress-bar-step-bg-color--active: transparent;
  --confirmation-box-bg-color: transparent;

  --box-border-color: transparent;
  --box-box-shadow-color: transparent;
  --box-text-color--active: transparent;
  --box-border-color--active: transparent;

  --parcel-btn-bg-color--hover: transparent;

  --network-btn-bg-color: transparent;
  --network-btn-bg-color--active: transparent;
  --network-btn-border-color: transparent;
  --network-btn-box-shadow-color: transparent;
  --brand-color-1: transparent;
  --brand-color-2: transparent;
  --brand-text-color: transparent;
  --language-bg-color: transparent;
  --network-btn-label-bg-color--active: transparent;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

html,
body {
  height: 100%;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  background: var(--background);

  *:focus {
    outline: none;
  }

  input[disabled],
  fieldset[disabled] .input,
  .textarea[disabled],
  fieldset[disabled] .textarea {
    background-color: var(--main-bg-color--light) !important;
    color: var(--main-text-color--disabled) !important;
  }
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;

  font-family: var(--font-family-primary);
  font-weight: var(--font-family-normal);

  @include size('font-size', 16px);
  color: var(--main-text-color);

  @include desktop {
    @include size('font-size', 20px);
  }

  button,
  input,
  select,
  textarea {
    font-family: var(--font-family-primary);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family-headers);
    color: var(--headers-text-color);
    word-wrap: break-word;
  }

  h1 {
    font-weight: var(--font-weight-h1);
    @include size('font-size', 28px);

    @include desktop {
      @include size('font-size', 24px);
    }
  }

  h2 {
    font-weight: var(--font-weight-h2);
    @include size('font-size', 21px);

    @include large-mobile {
      @include size('font-size', 30px);
    }
  }

  h3 {
    font-weight: var(--font-weight-h3);
    @include size('font-size', 18px);

    @include large-mobile {
      @include size('font-size', 25px);
    }
  }

  .dimmed {
    color: var(--headers-text-color--dimmed);
  }

  .content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  &-header.section {
    background-color: var(--header-bg-color);
  }

  .section {
    display: flex;
  }

  .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
  }

  .footer {
    flex-shrink: 0;
  }

  .lottie-wrapper {
    max-height: 300px;
  }

  .center-content {
    display: flex;
    justify-content: center;
  }

  .centered {
    text-align: center;
    margin: auto;
  }

  .small-margin {
    @include mobile {
      margin-left: -18px !important;

      .center-content {
        .cta-button {
          // margin-left: -32px;
        }
      }
    }
  }

  .button.is-loading::after {
    border-top-color: transparent;
    border-top-width: 3px;
    border-right-color: transparent;
    border-right-width: 3px;
    border-bottom-color: grey;
    border-bottom-width: 3px;
    border-left-color: grey;
    border-left-width: 3px;
  }
}