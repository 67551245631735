.countries-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__headers {
    width: 100%;
    text-align: center;
    margin: 5.5rem 0;
  }

  &__buttons {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}