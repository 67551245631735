
// Open Sans
@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-SemiBold.ttf');
  font-weight: var(--font-weight-normal);
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: var(--font-weight-h1);
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-ExtraBold.ttf');
  font-weight: 900;
}
