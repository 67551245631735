.time-slot__container {
  @include size("padding", 8px);

  .day-slider__container {
    @include size("margin-bottom", 40px);
  }

  .title {
    @include size(margin-bottom, 24px);
  }

  .time-slots-fields__container {
    @include size(min-height, 350px);
  }
}
