@mixin small-mobile {
  @media(min-width: 320px) {
    @content;
  }
}

@mixin large-mobile {
  @media(min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media(min-width: 680px) {
    @content;
  }
}

@mixin desktop {
  @media(min-width: 720px) {
    @content;
  }
}
