.progress {
  display: flex;
  justify-content: center;
  width: 23px;
  margin-top: 9px;

  .progress__bar {
    align-items: center;
    background-color: var(--progress-bar-bg-color);
    @include size("border-radius", 19px);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .progress__step {
      @include size("height", 10px);
      @include size("width", 10px);
      background-color: var(--progress-bar-step-bg-color);
      border-radius: 100%;
      @include size("margin-bottom", 2px);
      @include size("margin-top", 2px);

      &.progress__step--checked {
        @include size("height", 16px);
        @include size("width", 16px);
        background-color: var(--progress-bar-step-checked-bg-color);
        z-index: 1;
        background-image: url("../../assets/progressbarTick.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 0.5rem 0.5rem;

        &:hover {
          cursor: pointer;
        }
      }

      &.last {
        margin-bottom: 8px;
      }
    }

    .progress__step--current {
      @include size("height", 40x);
      @include size("width", 40px);
      align-items: center;
      background-color: var(--progress-bar-step-bg-color--active);
      border-radius: 100%;
      color: var(--main-text-color--light);
      display: flex;
      z-index: 2;
      font-weight: var(--font-weight-normal);
      justify-content: center;
      transform: translateY(50%);
      box-shadow: -0.4rem 0.5rem 1.2rem 0.3rem rgba(black, 0.15);

      span {
        @include size("height", 32px);
        @include size("width", 32px);
        @include size("font-size", 24px);
      }

      &.progress__step--toned-down {
        span {
          @include size("height", 10px);
          @include size("width", 10px);
          background-color: var(--progress-bar-step-bg-color);
          border-radius: 100%;
        }
      }

      position: absolute;
      bottom: 0;
    }
  }

  .progress__fill {
    background-image: linear-gradient(var(--progress-bar-gradient-color-1), var(--progress-bar-gradient-color-2));
    border-radius: 1.25rem 1.25rem 0 0;
    @include size("width", 19px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;

    span {
      img {
        @include size("margin-top", 3px);
        @include size("height", 25px);
      }
    }
  }
}