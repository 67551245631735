.field-select {
  &--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      height: 100px;
    }
  }

  &--options--container {
    cursor: pointer;
  }

  &--options {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
    max-height: 70vh;
    overflow-y: scroll;

    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;

    color: var(--field-select-text-color);
    border: 2.5px solid var(--field-select-text-color);
    background: var(--field-select-bg-color) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: .5rem;
    
    &--chosen {
      color: var(--box-text-color--active);
      border-color: var(--box-border-color--active);
      padding: .5rem;
      text-align: center;
    }

    &--with-errors {
      color: var(--error-text-color);
      border: 2.5px solid var(--error-border-color);
    }

    @include mobile {
      width: 80vw;
      top: 2.2rem;
      left: 0;
    }
  }

  &--label {
    position: unset !important;
  }

  &--prompt {
    font-size: 1.125rem;
    font-weight: var(--font-weight-normal);
  }

  &--option {
    min-height: 2rem;
    width: 98%;
    text-align: center;
    font-weight: var(--font-weight-normal);
    letter-spacing: 0;
    color: var(--main-text-color);
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      font-size: 1.1rem;
      min-height: 2.5rem;
      height: 2.5rem;
    }

    &:hover {
      background: var(--main-bg-color--hover);
    }
  }
}

.control.control-no-hr {
  margin-top: 2rem;

  &::after {
    display: none;
  }
}