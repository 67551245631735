@import 'assets/stylesheets/colors';

.shamed-emoji__container {
  display: flex;
  justify-content: center;

  .shamed-emoji__wrapper {
    background: var(--main-gradient);
    border-radius: 50%;
    width: 116px;
    height: 116px;
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 80px;
      margin: auto;
    }
  }
}
