.checkbox__container {
  font-weight: var(--font-weight-normal);
  @include size("font-size", 15px);
  @include size("margin-bottom", 12px);
  @include size("padding-left", 25px);
  cursor: pointer;
  display: block;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

/* Create a custom checkbox */
.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  @include size("height", 20px);
  @include size("width", 20px);
  @include size("border-radius", 3px);
  border: 0.125rem solid var(--scn-border-color);
  background-color: transparent;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.checkbox__link {
  text-decoration: underline;
  font-weight: var(--font-weight-normal);
  @include size("font-size", 15px);
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  color: var(--error-text-color);

  &:hover {
    color: var(--input-text-color);
  }
}

/* Create the checkbox__checkmark/indicator (hidden when not checked) */
.checkbox__checkmark:after {}

/* Show the checkbox__checkmark when checked */
.checkbox__container input:checked~.checkbox__checkmark:after {
  display: block;
}

/* Style the checkbox__checkmark/indicator */
.checkbox__container .checkbox__checkmark {
  &:after {
    @include size("left", 4px);
    @include size("top", 1px);
    @include size("width", 8px);
    @include size("height", 12px);
    border: solid var(--scn-border-color);
    @include size("border-width", 0, 3px, 3px, 0);
    @include size("border-radius", 2px);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkbox__wrapper {
  display: flex;
}

.checkbox__wrapper-with-errors {
  color: var(--error-text-color);

  .checkbox__checkmark {
    border-color: var(--error-border-color);

    &:after {
      border-color: var(--error-border-color);
    }
  }
}