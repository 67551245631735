@import 'assets/stylesheets/colors';

.slot-button {
  font-weight: var(--font-weight-normal);
  @include size("font-size", 16px);
  @include size("height", 51px);

  background-color: var(--scn-btn-bg-color);
  @include size("border-radius", 15px);
  border: 2px solid var(--scn-btn-border-color);
  color: var(--scn-btn-text-color);
  width: 100%;

  &:hover {
    transition: all ease .25s;
    background: var(--scn-btn-bg-color--hover);
    color: var(--scn-btn-text-color--hover);
    border: 2px solid var(--scn-btn-bg-color--hover);
  }

  &.slot-button--active {
    font-weight:800;
    @include size("font-size", 16px);
    background: var(--scn-btn-bg-color--active);
    border: 0;
    color: var(--scn-btn-text-color--active);
  }

  &.default {
    font-weight: var(--font-weight-normal);
    @include size("font-size", 24px);
  }
}
