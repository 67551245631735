$size-baseline: 16;

@import '../../assets/stylesheets/mixins/size';
@import '../../assets/stylesheets/mixins/media-queries';
@import '../../assets/stylesheets/vars';
@import '../../assets/stylesheets/bulma-configuration';
@import '../../assets/stylesheets/fonts';
@import 'node_modules/bulma/sass/utilities/_all.sass';

$label-base-margin: 6;
$label-up-margin: 40;
$span-up-margin: 40;
$border-margin-base: 6;

.field-file__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }
}

.field-file__wrapper-with-errors {
  & .field-file__label {
    color: var(--error-text-color);
  }

  & .field-file__button {
    &,
    &:focus,
    &:active,
    &:hover {
      border: 2.5px solid var(--error-border-color);
      color: var(--error-text-color);
    }
  }
}

button.field-file__button {
  color: var(--field-select-text-color);
  border: 2.5px solid var(--field-select-text-color);
  background: var(--field-select-bg-color) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  width: 17rem;
  height: 3rem;


  font-weight: var(--font-weight-normal);
  @include size('font-size', 18px);
  position: absolute;
  @include size(bottom, $label-base-margin);
  @include size(left, 0);

  @include mobile {
    width: 100%;
    margin-top: .5rem;
  }

  &,
  &:focus,
  &:active,
  &:hover,
  &:active {
    bottom: unset;
    position: unset !important;
    background: var(--field-select-bg-color) 0% 0% no-repeat padding-box;
    color: var(--field-select-text-color);
    border: 2.5px solid var(--field-select-text-color);
    outline: none !important;
  }

  &--chosen {
    border-color: var(--box-border-color--active);
    color: var(--box-text-color--active);
  }

}

label.field-file__label {
  &,
  &:focus,
  &:active,
  &:hover,
  .control & {
    bottom: unset;
    position: unset !important;
  }

  font-weight: var(--font-weight-normal);
  @include size('font-size', 18px);
  position: absolute;
  @include size(bottom, $label-base-margin);
  @include size(left, 0);

  @include mobile {
    width: 100%;
  }
}

.field-file__inputs {
  display: flex;
  flex-direction: column;
  align-items:center;

  @include mobile {
    width: 100%;
    margin-top: .5rem;
  }
}

.field-file__error {
  margin-top: .5rem;
  font-weight: var(--font-weight-normal);
  @include size("font-size", 14px);
  color: var(--error-text-color);
}