// @import '../../assets/scss/variables.sass';
@import 'assets/scss/mixins.sass';

.switch-language {
  &--flags {
    // position: absolute;
    // top: 1rem;
    // right: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.switch-language--open {
      .switch-language__lang {
        &--0 {
          transform: translateY(3rem)
        }

        &--1 {
          transform: translateY(6rem)
        }

        &--2 {
          transform: translateY(9rem)
        }

        &--3 {
          transform: translateY(12rem)
        }
      }
    }
  }

  &--flags & {
    &__lang {
      transition: all .2s ease-in-out;
      background-color: #ecf0f8;
      border-radius: 100%;
      border: none;
      height: 2.7rem;
      padding: 0;
      width: 2.7rem;
      // margin-bottom: 9px;
      position: absolute;
      z-index: 50;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(0);

      @include landscape {
        height: 2.2rem;
        width: 2.2rem;
      }

      img {
        width: 1.8rem;

        @include landscape {
          width: 1.5rem;
        }
      }

      &--selected {
        z-index: 100;
      }
    }
  }
}