@import 'assets/stylesheets/colors';

.footer {
  background-image: var(--footer-gradient);
  color: var(--footer-text-color);
  font-weight:800;
  @include size("font-size", 16px);
  @include size(padding, 8px);

  .returnista-logo {
    background-position: right;
    background-repeat: no-repeat;
    display: inline-block;
    @include size(height, 15px);
    @include size(width, 100px);

    @include tablet {
      @include size(margin-right, 20px);
    }

    #brandBar {
      fill: var(--footer-logo-color);
    }
  }
}
