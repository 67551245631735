.time-slots-not-available__wrapper{
  text-align: center;
  .time-slots-not-available__jumbo{
    border-radius: 15px 15px 15px 15px;
    border: 2px solid var(--prm-btn-text-color);
    height: 50px;
    line-height: 50px;
    padding: 0 9px;
    margin: 18px 0;


    font-weight: var(--font-weight-normal);
    @include size('font-size', 22px);
    letter-spacing: 0;
    text-align: center;
  }
  .cta-button{
    margin-top: 100px;
  }
}